div.chef-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

div.chef-wrap {
  padding: 8px 4px;
  margin: 10px 0px;
  cursor: pointer;
  position: relative;
}

button.chef-logout {
  outline: none;
  border: none;
  background: none;
  text-transform: uppercase;
  font-style: normal;
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B';
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: 600;
  align-self: flex-end;
  margin: 10px 10px;
  cursor: pointer;
}

path.chef {
  stroke-linecap: round;
  stroke-linejoin: round;
}

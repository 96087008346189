@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

section.ingredient-list {
  animation: 400ms cubic-bezier(0.165, 0.84, 0.44, 1) fadeIn;
  margin-bottom: 200px;
  padding-left: 0px;
}

div.inglist-item {
  padding: 2px 0px;
  height: 55px;
  width: 500px;
  margin-left: 0px;
  cursor: pointer;
  white-space: nowrap;
}

div.inglist-flex {
  display: flex;
}
div.inglist-flex svg {
  flex: none;
  height: 48px;
}

div.inglist-text {
  pointer-events: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2px;
  flex: none;
}

div.inglist-title {
  font-weight: 400;
  font-size: 17pt;
  text-transform: uppercase;
  margin: 5px;
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: none;
}
div.inglist-amt {
  font-size: 14pt;
  text-transform: lowercase;
  margin: 0px 10px;
}
div.inglist-cross {
  height: 3px;
  border-radius: 3px;
  width: 100%;
  position: relative;
  bottom: 46%;
  transition: width 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media only screen and (max-width: 800px) {
  section.ingredient-list {
    margin-bottom: 100px;
  }
  div.inglist-flex svg {
    height: 32px;
  }
  div.inglist-text {
    width: calc(100vw - 80px);
    justify-content: space-between;
  }
  div.inglist-title {
    width: auto;
    font-size: 15pt;
    flex-grow: 1;
  }

  div.inglist-item {
    width: calc(100vw - 40px);
    height: 30px;
    margin: 10px 20px;
  }
  div.inglist-amt {
    width: 100px;
    font-size: 12pt;
    margin: 0px 10px;
  }
  div.inglist-cross {
    bottom: 64%;
  }
}

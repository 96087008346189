div.recipe-nav {
  position: -webkit-sticky;
  position: sticky;
  top: calc(100vh - 300px);
  left: 100vw;
  width: 220px;
  height: 300px;
}

div.recipe-nav-blur {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

div.recipe-nav-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

div.recipe-nav-nav nav {
  display: flex;
  flex-direction: column;
}

div.recipe-nav-nav button {
  border: none;
  background: none;
  margin: 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

div.recipe-nav-head {
  padding: 0px 22px 4px 0px;
  z-index: 2;
}
div.recipe-nav-head h2 {
  font-style: normal;
  font-size: 13pt;
  font-weight: 600;
  padding: 0;
  margin: 0;
  text-align: right;
}

nav div.label {
  text-transform: uppercase;
  margin-left: 4px;
  font-size: 10pt;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  div.recipe-nav {
    display: none;
  }
}

div.plate-wrap {
  width: 240px;
  height: 250px;
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

div.plate-wrap a {
  text-decoration: none;
}

div.plate-mask {
  width: 240px;
  height: 160px;
  overflow: hidden;
  position: relative;
  object-fit: contain;
}

div.plate-wrap img {
  width: 240px;
  height: 160px;
  object-fit: cover;
  z-index: -1;
  position: relative;
}

div.plate-main {
  pointer-events: none;
}

h1.plate {
  margin: 0;
  padding: 0;
  width: 240px;
  min-height: 49px;
  font-size: 18px;
  font-weight: 400;
  text-transform: lowercase;
}

div.plate-acts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% + 16px);
}

div.plate-acts button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  pointer-events: all;
  font-weight: 500;
  height: 44px;
  padding: 0;
  stroke-width: 3px;
  width: 40px;
}

div.plate-act-heart {
  transform: translateY(-2.5px);
  font-size: 17px;
  pointer-events: none;
}

.plate-act-heart svg {
  width: 24px;
}

/* div.plate-act-left {
  position: relative;
  width: 44px;
  height: 50px;
} */
/* button.plate-act-save {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
}

svg.plate-act-save {
  width: 34px;
} */

/* svg.burst {
  width: 46px;
  height: 46px;
  margin: 2px 0px 0px 2.4px;
  pointer-events: none;
  position: absolute;
}
svg.burst path {
  stroke-width: 0px;
  stroke-linecap: round;
} */

@media only screen and (max-width: 600px) {
  div.plate-wrap {
    width: 100vw;
    /* height: 70vw; */
    margin: 70px 0px;
  }

  div.plate-mask {
    width: 100%;
    height: 60vw;
  }

  div.plate-wrap img {
    width: 100vw;
    height: auto;
  }

  h1.plate {
    margin: 0 auto;
    font-size: 22px;
    width: calc(100vw - 100px);
  }

  div.plate-acts {
    width: 60px;
  }

  div.plate-flex {
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 20px);
  }

  div.plate-acts button {
    height: 60px;
    width: 60px;
  }

  div.plate-act-heart {
    transform: translateY(-3.5px);
    font-size: 24px;
  }

  .plate-act-heart svg {
    width: 32px;
  }
}

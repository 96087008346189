div.recipe-splash {
  height: 100vh;
  display: grid;
  place-content: center;
}

div.splash-mask {
  width: 700px;
  overflow: hidden;
  position: relative;
  object-fit: contain;
}

div.recipe-splash img {
  width: 700px;
  object-fit: cover;
  z-index: -1;
  position: relative;
}

div.recipe-forward-title,
div.recipe-forward-detail {
  padding: 0px 220px 60px 120px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.25em;
}

div.recipe-forward-title {
  width: calc(100vw - 320px);
}

div.recipe-forward-title h1 {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.25em;
  margin: 0px 0px 30px 0px;
}

div.recipe-forward-src {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

div.recipe-forward-src svg {
  height: 30px;
  margin: 0px 20px;
}

div.recipe-forward-title h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.25em;
  margin: 0px;
}

div.recipe-frame {
  position: relative;
}

div.recipe-chapters {
  width: 100vw;
  overflow: visible;
}

main.recipe {
  padding-left: 120px;
}

@media only screen and (max-width: 800px) {
  div.recipe-splash {
    height: auto;
    display: grid;
    place-content: center;
  }
  div.splash-mask {
    width: 100vw;
  }

  div.recipe-splash img {
    width: 100vw;
  }
  main.recipe {
    padding-left: 0px;
  }

  div.recipe-forward-title {
    padding: 20px;
    width: calc(100vw - 40px);
  }

  div.recipe-splash-mask {
    max-width: 100vw;
    max-height: 450px;
    overflow: hidden;
  }

  div.recipe-forward-title h1 {
    font-size: 40px;
  }

  div.recipe-forward-title h2 {
    font-size: 20px;
  }

  div.recipe-forward-src svg {
    height: 24px;
  }

  div.recipe-forward-src {
    margin-left: 0px;
  }

  div.recipe-forward-detail {
    margin: 10px 0px 100px 0px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.25em;
    width: calc(100vw - 40px);
    padding: 20px;
  }
}
/* 
@media only screen and (max-width: 800px) {
  div.recipe-splash-mask {
    width: 90vw;
    max-height: 220px;
    margin: 120px 0 0 5vw;
  }
} */

div.menu {
  width: 100vw;
  max-height: 100vh;
}

div.welcome-wrap {
  min-height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
}
div.welcome {
  padding: 0px 70px;
  width: calc(100vw - 140px);
  max-width: 1000px;
  height: 50vw;
  max-height: 580px;
  font-family: 'Canteen';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 8vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

div.welcome > div {
  justify-self: center;
  align-self: center;
  user-select: none;
}

div.pronounce {
  font-size: 12pt;
  text-align: center;
  font-family: 'Archer A', 'Archer B';
  font-style: italic;
}

div.tutorial-wrap {
  display: flex;
  justify-content: center;
}
div.tutorial {
  padding: 30px;
  margin: 80px 0px 40px 0px;
  font-size: 20pt;
}

section.menu-section {
  width: 100vw;
  max-width: 1000px;
  margin: 0 auto;
}

div.menu-section-main {
  display: flex;
  justify-content: center;
  overflow: scroll;
  padding: 0px;
  max-width: 100%;
  flex-wrap: wrap;
}

.sha {
  width: 50px;
  height: 50px;
}

div.learn {
  padding: 40px;
  padding-top: 200px;
  margin-top: 100px;
  margin-bottom: 200px;
  width: calc(100vw - 70px);
  position: relative;
}
div.learn-aside {
  padding-top: 500px;
  position: absolute;
  top: 0px;
  left: calc(60% - 0px);
}

div.learn-example {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

div.learn-item {
  height: 90vh;
  position: relative;
}

div.learn-item2 {
  position: relative;
  height: 40vh;
}
div.learn-title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  text-align: left;
  margin-right: 40px;
}
div.learn-title div {
  font-size: 28px;
}
div.learn-ref {
  position: absolute;
  top: 20%;
  width: 50px;
}

div.learn-p {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 32px;
}
div.learn-steps {
  position: relative;
  display: flex;
}

div.learn-steps-refs {
  position: relative;
}

div.learn-step {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 32px;
  width: 200px;
}

div.learn-step-ref {
  width: 3px;
  position: absolute;
  bottom: 0;
}

div.about {
  font-size: 28px;
  width: 80vw;
  max-width: 1000px;
  margin: 100px auto;
  line-height: 1.1em;
}

@media only screen and (min-width: 1100px) {
  div.welcome {
    font-size: 90px;
  }
  svg.example {
    width: 620px;
  }
  div.learn-aside {
    left: 667px;
  }
}

@media only screen and (max-width: 800px) {
  div.welcome {
    padding: 0px 70px;
    width: calc(100vw - 140px);
    height: 70vh;
    font-size: 14vw;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  div.welcome > div {
    height: 80px;
  }

  div.pronounce {
    position: absolute;
    bottom: 40px;
    width: 100vw;
  }

  div.learn {
    width: 100vw;
    padding: 0px;
    margin-top: 300px;
  }

  div.learn-aside {
    left: 0px;
    padding: 0px;
  }

  div.learn-title {
    font-size: 70px;
    margin-left: 20px;
    margin-right: 0px;
  }
  div.learn-title div {
    font-size: 32px;
  }
  div.about {
    font-size: 24px;
    width: 90vw;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 600px) {
  div.welcome {
    height: 60vh;
  }
}

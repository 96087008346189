div.preheat {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 60px;
}

div.preheat > span {
  font-size: 28px;
}

foreignObject.leaf {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  overflow: visible;
  justify-content: flex-start;
  align-items: center;
}

div.leaf-tap {
  display: inline-flex;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
  padding: 13px 13px 13px 0px;
}

img.leaf-svg,
svg.leaf-svg {
  margin: 0px 10px;
}

div.leaf-lab {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 400;

  font-size: 17pt;
  /* font-weight: 500; */
  text-transform: uppercase;
}

div.leaf-name {
  font-weight: 400;
  font-size: 17pt;
  /* font-weight: 500; */
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
  user-select: none;
}

div.leaf-more {
  font-size: 11pt;
  text-transform: lowercase;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  user-select: none;
}

/* .child .detail {
  font-size: 8pt;
  font-weight: 400;
  text-transform: lowercase;
} */

.transfer-text {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  transform: translate(8px, 6px);
}
path.transfer {
  stroke-width: 12px;
  stroke-dasharray: none;
}

text.timer-countdown {
  font-size: 15px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.6px;
  text-anchor: middle;
}

@media only screen and (max-width: 800px) {
  div.leaf-name {
    font-size: 17px;
  }

  div.leaf-more {
    font-size: 11px;
    line-height: 1.1em;
    max-width: 138px;
  }
  text.timer-countdown {
    font-size: 14px;
  }

  .transfer-text {
    font-size: 15px;
    transform: translate(4px, 2px);
  }

  path.transfer {
    stroke-width: 3px;
    stroke-dasharray: 0 8;
    stroke-linecap: round;
  }

  div.preheat {
    font-size: 18px;
    padding-left: 20px;
  }
}

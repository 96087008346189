div.portion {
  display: flex;
  margin: 0px 32px 20px 10px;
  align-items: center;
  width: fit-content;
}

div.portion-servings {
  margin: 0px 0px 0px 0px;
}

div.portion-servings {
  cursor: pointer;
  width: 80px;
}

div.portion-cur {
  flex: none;
  transform-origin: center;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 27px;
  width: 100%;
  text-align: center;
}

div.portion-control {
  position: relative;
  width: 30px;
  height: 80px;
  font-weight: 700;
  font-size: 17pt;
  font-family: 'Open Sans', sans-serif;
}

div.portion-control button {
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  text-align: center;
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
}

div.portion-x {
  pointer-events: none;
}

svg.portion-control-svg {
  transform-origin: center;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

@font-face {
  font-family: 'Canteen';
  src: url('../../../media/fonts/canteen/canteen-medium.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Canteen';
  src: url('../../../media/fonts/canteen/canteen-bold.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Noir';
  src: url('../../../media/fonts/noir/noir.otf');
}

@font-face {
  font-family: 'Thabit-bold';
  src: url('../../../media/fonts/thabit/thabit-bold.ttf');
}

/* ====================== */

.font1 {
  font-family: 'Thabit-bold';
}

.font2 {
  font-family: 'Noir';
}

.font3 {
  font-family: 'Canteen';
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overscroll-behavior-y: none;
}
/* ====================== */

main.kitchen {
  min-height: 100vh;
}

/* ====================== */
header {
  z-index: 3;
  position: absolute;
  top: 0px;
  left: 0px;
}

header > div {
  width: 56px;
  user-select: none;
}
header svg.plate-svg {
  width: 50px;
}
div.head-wrap {
  width: calc(100% - 20px);
  padding: 10px;
  cursor: pointer;
}

div.nav-label {
  position: absolute;
  top: 21px;
  left: 0;
  height: 20px;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
}

/* ======================= */

footer {
  width: 100vw;
  margin-top: 200px;
  position: relative;
}

footer > div.copyright {
  width: calc(100% - 20px);
  margin: 10px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

footer div {
  font-size: 20px;
  font-weight: 500;
}
div.copyright > div {
  padding-bottom: 4px;
}

footer div.source {
  position: absolute;
  top: -10px;
  left: -300px;
  opacity: 0;
  cursor: default;
  padding: 10px 10px 10px 300px;
}
@media only screen and (max-width: 700px) {
  header {
  }

  header svg.plate-svg {
    /* width: 50px; */
  }
}
